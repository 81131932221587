.fade-enter-active, .fade-leave-active {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
/* workaround for https://github.com/bootstrap-vue/bootstrap-vue/issues/1560 */
/* source: _input-group.scss */
.input-group > .input-group-prepend > .b-dropdown > .btn,
.input-group > .input-group-append:not(:last-child) > .b-dropdown > .btn,
.input-group > .input-group-append:last-child > .b-dropdown:not(:last-child):not(.dropdown-toggle) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .b-dropdown > .btn,
.input-group > .input-group-prepend:not(:first-child) > .b-dropdown > .btn,
.input-group > .input-group-prepend:first-child > .b-dropdown:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/* Special styling for type=range and type=color input */
input.form-control[type="range"],
input.form-control[type="color"] {
    height: 2.25rem;
}
input.form-control.form-control-sm[type="range"],
input.form-control.form-control-sm[type="color"] {
    height: 1.9375rem;
}
input.form-control.form-control-lg[type="range"],
input.form-control.form-control-lg[type="color"] {
    height: 3rem;
}
/* Less padding on type=color */
input.form-control[type="color"] {
    padding: 0.25rem 0.25rem;
}
input.form-control.form-control-sm[type="color"] {
    padding: 0.125rem 0.125rem;
}
/* Add support for fixed layout table */
table.b-table.b-table-fixed {
    table-layout: fixed;
}
/* Busy table styling */
table.b-table[aria-busy='false'] {
    opacity: 1;
}
table.b-table[aria-busy='true'] {
    opacity: 0.6;
}
/* Sort styling */
table.b-table > thead > tr > th,
table.b-table > tfoot > tr > th {
    position: relative;
}
table.b-table > thead > tr > th.sorting,
table.b-table > tfoot > tr > th.sorting {
    padding-right: 1.5em;
    cursor: pointer;
}
table.b-table > thead > tr > th.sorting::before,
table.b-table > thead > tr > th.sorting::after,
table.b-table > tfoot > tr > th.sorting::before,
table.b-table > tfoot > tr > th.sorting::after {
    position: absolute;
    bottom: 0;
    display: block;
    opacity: 0.4;
    padding-bottom: inherit;
    font-size: inherit;
    line-height: 180%;
}
table.b-table > thead > tr > th.sorting::before,
table.b-table > tfoot > tr > th.sorting::before {
    right: 0.75em;
    content: '\2191';
}
table.b-table > thead > tr > th.sorting::after,
table.b-table > tfoot > tr > th.sorting::after {
    right: 0.25em;
    content: '\2193';
}
table.b-table > thead > tr > th.sorting_asc::after,
table.b-table > thead > tr > th.sorting_desc::before,
table.b-table > tfoot > tr > th.sorting_asc::after,
table.b-table > tfoot > tr > th.sorting_desc::before {
    opacity: 1;
}
/* Stacked table layout */
/* Derived from http://blog.adrianroselli.com/2017/11/a-responsive-accessible-table.html */
/* Always stacked */
table.b-table.b-table-stacked {
    width: 100%;
}
table.b-table.b-table-stacked,
table.b-table.b-table-stacked > tbody,
table.b-table.b-table-stacked > tbody > tr,
table.b-table.b-table-stacked > tbody > tr > td,
table.b-table.b-table-stacked > tbody > tr > th,
table.b-table.b-table-stacked > caption {
    display: block;
}
/* Hide stuff we can't deal with, or shouldn't show */
table.b-table.b-table-stacked > thead,
table.b-table.b-table-stacked > tfoot,
table.b-table.b-table-stacked > tbody > tr.b-table-top-row,
table.b-table.b-table-stacked > tbody > tr.b-table-bottom-row {
    display: none;
}
/* inter-row top border */
table.b-table.b-table-stacked > tbody > tr > :first-child {
    border-top-width: 0.4rem;
}
/* convert TD/TH contents to "cells". Caveat: child elements become cells! */
table.b-table.b-table-stacked > tbody > tr > [data-label] {
    display: grid;
    grid-template-columns: 40% auto;
    grid-gap: 0.25rem 1rem;
}
/* generate row cell "heading" */
table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
    content: attr(data-label);
    display: inline;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
}
@media all and (max-width: 575.99px) {
    /* Under SM */
    table.b-table.b-table-stacked-sm {
        width: 100%;
    }
    table.b-table.b-table-stacked-sm,
    table.b-table.b-table-stacked-sm > tbody,
    table.b-table.b-table-stacked-sm > tbody > tr,
    table.b-table.b-table-stacked-sm > tbody > tr > td,
    table.b-table.b-table-stacked-sm > tbody > tr > th,
    table.b-table.b-table-stacked-sm > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-sm > thead,
    table.b-table.b-table-stacked-sm > tfoot,
    table.b-table.b-table-stacked-sm > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-sm > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-sm > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-sm > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
@media all and (max-width: 767.99px) {
    /* under MD  */
    table.b-table.b-table-stacked-md {
        width: 100%;
    }
    table.b-table.b-table-stacked-md,
    table.b-table.b-table-stacked-md > tbody,
    table.b-table.b-table-stacked-md > tbody > tr,
    table.b-table.b-table-stacked-md > tbody > tr > td,
    table.b-table.b-table-stacked-md > tbody > tr > th,
    table.b-table.b-table-stacked-md > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-md > thead,
    table.b-table.b-table-stacked-md > tfoot,
    table.b-table.b-table-stacked-md > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-md > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-md > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-md > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
@media all and (max-width: 991.99px) {
    /* under LG  */
    table.b-table.b-table-stacked-lg {
        width: 100%;
    }
    table.b-table.b-table-stacked-lg,
    table.b-table.b-table-stacked-lg > tbody,
    table.b-table.b-table-stacked-lg > tbody > tr,
    table.b-table.b-table-stacked-lg > tbody > tr > td,
    table.b-table.b-table-stacked-lg > tbody > tr > th,
    table.b-table.b-table-stacked-lg > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-lg > thead,
    table.b-table.b-table-stacked-lg > tfoot,
    table.b-table.b-table-stacked-lg > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-lg > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-lg > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-lg > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
@media all and (max-width: 1199.99px) {
    /* under XL  */
    table.b-table.b-table-stacked-xl {
        width: 100%;
    }
    table.b-table.b-table-stacked-xl,
    table.b-table.b-table-stacked-xl > tbody,
    table.b-table.b-table-stacked-xl > tbody > tr,
    table.b-table.b-table-stacked-xl > tbody > tr > td,
    table.b-table.b-table-stacked-xl > tbody > tr > th,
    table.b-table.b-table-stacked-xl > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-xl > thead,
    table.b-table.b-table-stacked-xl > tfoot,
    table.b-table.b-table-stacked-xl > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-xl > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-xl > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-xl > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
/* Details row styling */
table.b-table > tbody > tr.b-table-details > td {
    border-top: none;
}

#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}

h1[data-v-5a648cad], h2[data-v-5a648cad] {
  font-weight: normal;
}
ul[data-v-5a648cad] {
  list-style-type: none;
  padding: 0;
}
li[data-v-5a648cad] {
  display: inline-block;
  margin: 0 10px;
}
a[data-v-5a648cad] {
  color: #42b983;
}

.errors[data-v-68e364fc] {
  color: white;
}

#questionario_form_submission .b-form-group label[data-v-7cebc819] {
  font-size: 1.2rem ;
}
.step-container[data-v-7cebc819] {
  min-height: 500px;
}
































































































































































.date-dropdown-container[data-v-18fd6056] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.date-dropdown-select[data-v-18fd6056] {
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 8px;
  margin-right: 10px;
}
